// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyDZE-ORMpe2Gje6p6h31ufwqkHmepsJ7sE",
  authDomain: "transformacionproductiva-a690a.firebaseapp.com",
  databaseURL: "https://transformacionproductiva-a690a.firebaseio.com",
  projectId: "transformacionproductiva-a690a",
  storageBucket: "transformacionproductiva-a690a.appspot.com",
  messagingSenderId: "347741265852",
  appId: "1:347741265852:web:1593b0660660db51c1d5b9",
  measurementId: "G-VTY1ETDH06"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/transformacionproductiva-a690a.appspot.com/o/'